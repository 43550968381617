<template>
  <b-container>
    <b-row>
      <b-col cols="1"/>
      <b-col cols="10">
        <h2 style="hyphens: auto; margin-bottom: 2rem;">Nutzungs&shy;bedingungen</h2>
      </b-col>
      <b-col cols="1"/>
    </b-row>

    <b-row>
      <b-col cols="1"/>
      <b-col cols="8">
        <h4>Standortzugriff</h4>
      </b-col>
      <b-col cols="2">
        <b-checkbox v-model="toggleGps" switch size="lg"></b-checkbox>
      </b-col>
      <b-col cols="1"/>
    </b-row>
    <b-row>
      <b-col cols="1"/>
      <b-col cols="8">
        <p  >
          Diese App muss auf deinen Standort zugreifen können, um deine Position und die Position des Fahrrads zu bestimmen.
        </p>
      </b-col>
      <b-col cols="3"/>
    </b-row>

    <b-row>
      <b-col cols="1"/>
      <b-col cols="8">
        <h4>Mitteilungen</h4>
      </b-col>
      <b-col cols="2">
        <b-checkbox v-model="toggleNotifications" switch size="lg"></b-checkbox>
      </b-col>
      <b-col cols="1"/>
    </b-row>
    <b-row>
      <b-col cols="1"/>
      <b-col cols="8">
        <p  >
          Erlaube der App dir Mitteilungen zu senden, um auf dem neuesten Stand zu sein und keine wichtigen Infos zu verpassen.
        </p>
      </b-col>
      <b-col cols="3"/>
    </b-row>

    <b-row>
      <b-col cols="1"/>
      <b-col cols="8">
        <h4>Kamerazugriff</h4>
      </b-col>
      <b-col cols="2">
        <b-checkbox v-model="toggleCam" switch size="lg"></b-checkbox>
      </b-col>
      <b-col cols="1"/>
    </b-row>
    <b-row>
      <b-col cols="1"/>
      <b-col cols="8">
        <p style="margin-bottom: 7rem;">
          Erlaube der App bei Gebrauch dieser auf deiner Kamera zuzugreifen, um den Status des Bikes festzuhalten.
        </p>
      </b-col>
      <b-col cols="3"/>
    </b-row>
    <!-- disabled button as long as not all permissions are given -->
    <NextButton text="Weiter" :disabled="!toggleValid" next @click="$router.push('/appTutorial/1')" />
    <div class="box"/>
  </b-container>
</template>

<script>
  import NextButton from '../components/NextButton';
  export default {
    name: 'ToU',
    components: {
      NextButton,
    },
    data() {
      return {
        toggleGps: false,
        toggleNotifications: true,
        toggleCam: false,
      }
    },
    computed: {
      toggleValid() {
        // checks if permissions are given
        return this.toggleGps && this.toggleNotifications && this.toggleCam;
      }
    },
    watch: {
      toggleGps: function() {
        // requests geolocation permission
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(()=> {
            this.toggleGps = true
          }, (error) => {
            switch(error.code) {
              case error.PERMISSION_DENIED:
                this.toggleGps = false;
                break;
              case error.UNKNOWN_ERROR:
                this.toggleGps = false;
                break;
              }
          });
        } else {
          console.log('no gps')
          this.toggleGps = false;
        }
      },
      toggleCam: function() {
        // requests camera permission
        navigator.mediaDevices.getUserMedia({ video: true })
        .then(() => {
          this.toggleCam = true;
          return;
        })
        .catch(() => {
          this.toggleCam = false;
          return;
        });
      }
    }
  }
</script>

<style>

</style>